import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/Nafi-Logo.svg"
import tw, { styled } from "twin.macro"
import { gsap } from "gsap"
import Player from "@vimeo/player"
import References from "../components/references"
import ReferencesMobile from "../components/references-mobile"
const IndexPage = () => {
  const colors = ["red", "purple", "yellow"]
  //var randomColor = colors[Math.floor(Math.random() * colors.length)]
  const [randomColor, setColor] = useState(null)

  const randomizeColor = () => {
    return colors[Math.floor(Math.random() * colors.length)]
  }
  const changeColor = () => {
    setColor(randomizeColor)
  }
  useEffect(() => {
    setColor(randomizeColor)
    let color = "#FF6D53" //red
    if (randomColor === "yellow") {
      color = "#FFDE53"
    }
    if (randomColor === "purple") {
      color = "#8853FF"
    }
    gsap.to("#logo-background", {
      background: color,
      ease: "ease-in-out",
      duration: 0.3,
    })
  })
  const ScrollContainer = styled.div`
    position: relative;
    overflow-x: hidden;
  `
  const ContentContainer = styled.div`
    position: relative;
    min-height: 50vh;
    pointer-events: none;
  `
  const BlankContainer = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    background: transparent;
  `
  const InstaContainer = styled.a``
  const LogoBackground = styled.div`
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
  `
  const LogoImg = styled.img`
    ${tw`w-3/4 lg:w-3/5 h-auto`}
  `
  const ContactContainer = styled.div`
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${tw`py-20 sm:py-64 px-4 text-xs sm:text-sm w-screen  lg:text-lg`}
    ${({ color }) => color === "yellow" && tw`text-yellow`}
    ${({ color }) => color === "purple" && tw`text-purple`}
    ${({ color }) => color === "red" && tw`text-red`}
  `
  const VideoContainer = styled.div`
    padding: 10vh 0;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
     ${({ color }) => color === "yellow" && tw`text-yellow`}
      ${({ color }) => color === "purple" && tw`text-purple`}
      ${({ color }) => color === "red" && tw`text-red`};
  `
  const ContactForm = styled.form`
    pointer-events: auto;
    ${tw`mt-16 px-4 sm:mt-32 w-full sm:w-4/5 md:w-4/5 lg:w-1/2 grid grid-rows-2 sm:grid-rows-none sm:grid-cols-2 sm:items-center sm:justify-center`}
  `
  const ContactButton = styled.button`
    ${tw`py-2 px-4 sm:py-4 sm:px-8`}
    ${({ background }) => background === "yellow" && tw`bg-yellow`}
    ${({ background }) => background === "purple" && tw`bg-purple`}
    ${({ background }) => background === "red" && tw`bg-red`}
    border-radius: 9999px;
    color: black;
    border: none;
  `

  const ContactInput = styled.input`
    background: transparent;
    ${tw`border-b py-2 px-4 mb-8 sm:mb-0 sm:mx-8 placeholder-opacity-100 text-center `}
    ${({ color }) =>
      color === "yellow" && tw`text-yellow border-yellow placeholder-yellow`}
    ${({ color }) =>
      color === "purple" && tw`text-purple border-purple placeholder-purple`}
    ${({ color }) =>
      color === "red" && tw`text-red border-red placeholder-red`};
  `
  const ContactFooter = styled.footer`
    pointer-events: auto;

    ${tw`flex flex-row items-center`}
  `
  const ContactLinks = styled.ul`
    ${tw`flex flex-row items-center`}
  `
  const IframeWrapper = styled.div`
    pointer-events: auto;
    width: 80vw;
    height: calc(80vw * 0.5625);
  `
  const VideoFrame = styled.iframe`
    ${tw`w-full h-full`}
  `

  const onChange = isVisible => {
    if (isVisible) {
      let el = document.getElementById("iframe-wrapper")
      var iframe = el.querySelector("iframe")
      var player = new Player(iframe)
      player.play()
    }
  }
  return (
    <Layout>
      <SEO
        title="NAFILM - video production"
        description="Kameramann, Allrounder, Teamplayer mit
Netzwerk, Phantasie und Feingefühl.
Leidenschaftlich, ideenreich, schmerzfrei.
Kein Mann vieler Worte, aber einer großer Bilder."
      />

      <ScrollContainer>
        <LogoBackground id="logo-background" background={randomColor}>
          <LogoImg src={logo}></LogoImg>
        </LogoBackground>

        <ContentContainer id="content-container">
          <BlankContainer></BlankContainer>
          <VideoContainer>
            <IframeWrapper id="iframe-wrapper">
              <VideoFrame
                src="https://player.vimeo.com/video/453700329"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></VideoFrame>
            </IframeWrapper>
          </VideoContainer>
          <ContactContainer id="contact-container" color={randomColor}>
            <p>
              Kameramann, Allrounder, Teamplayer mit Netzwerk, Phantasie und
              Feingefühl. <br></br> Leidenschaftlich, ideenreich, schmerzfrei.{" "}
              <br></br>
              Kein Mann vieler Worte, aber einer großer Bilder.
            </p>
            <ContactFooter>
              <ContactLinks>
                <li css={tw`my-24`}>
                  <a
                    css={tw`hover:underline`}
                    href="https://www.instagram.com/nafilm__/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    css={tw`mx-12 hover:underline`}
                    href="mailto:hallo@nafilm.de"
                    target="_blank"
                    rel="noreferrer"
                  >
                    E-Mail
                  </a>
                </li>
                <li>
                  <a
                    css={tw`hover:underline`}
                    href="https://www.linkedin.com/in/ahmed-nafi-32a8b0108/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
              </ContactLinks>
            </ContactFooter>
            <References color={randomColor}></References>
            <ReferencesMobile color={randomColor}></ReferencesMobile>
          </ContactContainer>
        </ContentContainer>
      </ScrollContainer>
    </Layout>
  )
}

export default IndexPage

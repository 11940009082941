import React, { useState, useEffect } from "react"
import axios from "axios"
import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

const ZdfLogo = ({ color }) => {
  return (
    <svg
      version="1.1"
      id="svg2552"
      xmlnsSvg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1024 613"
      xmlSpace="preserve"
    >
      <path
        id="path2482"
        class="st0"
        fill="white"
        d="M682,391.5h-60.8v-168H682c47.8,0,64.9,20.4,64.9,84C746.9,371.1,729.8,391.5,682,391.5
	 M910.2,223.5h96.5v-45.8H890.4c-62.5,0-88.2,35-88.2,89.8l0,5.4c-10.5-64.6-48.8-95.2-119.1-95.2H565.4
	C518,82.7,419.9,17.4,306.5,17.4c-159.6,0-289.1,129.5-289.1,289.2c0,159.7,129.5,289,289.1,289c112.7,0,210.2-64.4,257.9-158.3
	h120.1c28.8,0,54.6-1.7,81-22.8c19-15.1,30.9-36.3,36.4-64.2v86.6H857l0.1-102h130v-45.4H857l0.7-18.4
	C857.8,241.4,866.7,223.5,910.2,223.5"
      />
      <path
        id="path2486"
        class="st0"
        d="M434.5,285.2h35.9c28.6,0,45.4-5.7,45.4-29.9c0-24.9-16.8-31.8-46.1-31.8H347.9v-45.8h141.6
	c71.9,0,82,50.3,82,78.3c0,29.9-12.2,77.2-82.1,77.2h-34.8c-43.5,0-52.9,15.7-52.9,45.5v13.9h162.6v44.3H346.3v-64.4
	C346.3,317.8,372.1,285.2,434.5,285.2"
      />
    </svg>
  )
}

export default ZdfLogo
